import React, { useState } from "react";
import styled from "@emotion/styled";

const LoadingIFrame = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Loading isLoading={isLoading}>
        Great content takes time to load...
      </Loading>
      <iframe
        width="100%"
        height="100%"
        style={{
          aspectRatio: 2,
          border: "none",
        }}
        src="https://www.youtube.com/embed/UCh5bUkMVt4"
        title="Benimaru, the Substitute teacher - Fire Force Skit"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </>
  );
};

export default LoadingIFrame;

const Loading = styled.h3`
  display: flex;
  position: absolute;
  background: turquoise;
  width: ${({ isLoading }) => (isLoading ? "100%" : 0)};
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  transition: width 0s linear 0.5s, opacity 0.5s linear;
`;
