import React from "react";
import styled from "@emotion/styled";
import jeffWave from "../Assets/jeffWave.png";
import { FaTwitch, FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";
import { sizeStyles } from "./CardContainer";

const SocialHeader = (props) => {
  const Links = [
    {
      href: "https://twitch.tv/Jeffery_zang",
      icon: FaTwitch,
    },
    {
      href: "https://youtube.com/channel/UCDlP9jgPzVMNdWhgnCs6W4w",
      icon: FaYoutube,
    },
    {
      href: "https://instagram.com/jeffery_zang",
      icon: FaInstagram,
    },
    {
      href: "https://tiktok.com/@jeffery_zang",
      icon: FaTiktok,
    },
  ];
  return (
    <SocialDiv>
      <SocialTop>
        <SocialImg src={jeffWave} />
        <SocialDesc>
          <SocialTitle>@jeffery_zang</SocialTitle>
          <SocialSubtitle>
            Business email:{" "}
            <a href="mailto:JeffZhang@ViralNationTalent.com?subject=Business&body=Message">
              JeffZhang@ViralNationTalent.com
            </a>
          </SocialSubtitle>
        </SocialDesc>
      </SocialTop>
      <SocialLinks>
        {Links.map((link, idx) => (
          <SocialLink key={`socialHeaderLink_${idx}`} href={link.href}>
            <link.icon size={28} />
          </SocialLink>
        ))}
      </SocialLinks>
    </SocialDiv>
  );
};

export default SocialHeader;

const SocialDiv = styled.div`
  ${sizeStyles}
  display: flex;
  flex-direction: column;
  background-color: inherit;
  border-radius: inherit;
`;

const SocialTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

const SocialImg = styled.img`
  display: flex;
  width: 65px;
  aspect-ratio: 1 / 1;
  margin-right: 12px;
`;

const SocialDesc = styled.div`
  display: flex;
  flex-direction: column;
`;

const SocialTitle = styled.span`
  font-weight: 400;
  font-size: 22px;
`;
const SocialSubtitle = styled.span`
  margin-top: 8px;
  /* font-family: Solwy, sans-serif; */
  font-weight: 400;
  font-size: 16px;
`;

const SocialLinks = styled.div`
  display: flex;
`;

const SocialLink = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
