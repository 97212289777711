import React from "react";
import styled from "@emotion/styled";
import CardContainer from "./CardContainer";

const ImageClickableCards = ({ link, header, subtext, img }) => {
  return (
    <CardContainer link={link}>
      <ImageContainer bg={img} />
      <TextContainer>
        <Header>{header}</Header>
        <Subtext>{subtext}</Subtext>
      </TextContainer>
    </CardContainer>
  );
};

export default ImageClickableCards;

const ImageContainer = styled.div`
  display: flex;
  flex: 50% 1;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  width: 100%;
  aspect-ratio: 1;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 50% 1;
  /* height: 0; */
  /* overflow: hidden; */
  /* padding-bottom: 25%;
  padding-top: 25%; */
`;

const Header = styled.h3`
  margin: 5px;
`;

const Subtext = styled.h4`
  margin: 0px;
  font-weight: 400;
`;
