import React from "react";
import styled from "@emotion/styled";
import { cardStyles } from "./CardContainer";

const MailingCard = (props) => {
  return (
    <MailingCardContainer>
      <InnerContainer>
        <MailingHeader>MAILING ADDRESS</MailingHeader>
        <MailingBody>
          If you'd like to send me anything, here's my PO Box!
          <br />
          <br />
          Jeffery Zhang
          <br />
          PO Box 130469, 3000 Green Rd Ann Arbor MI 48113, USA
        </MailingBody>
      </InnerContainer>
    </MailingCardContainer>
  );
};

export default MailingCard;

const MailingCardContainer = styled.div`
  ${cardStyles({ noHover: true })}
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const MailingHeader = styled.h4`
  /* font-size: 10px; */
  margin-top: 0px;
`;

const MailingBody = styled.span`
  margin-top: 8px;
  align-self: start;
`;
