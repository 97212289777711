import React from "react";
import styled from "@emotion/styled";
import CardContainer from "./CardContainer";

const ClickableCards = ({ title, link }) => {
  return (
    <CardContainer link={link}>
      <Title>{title}</Title>
    </CardContainer>
  );
};

export default ClickableCards;

const Title = styled.h4``;
