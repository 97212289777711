import React from "react";
import ImageClickableCard from "./ImageClickableCards";
import ClickableCards from "./ClickableCards";
import CardContainer from "./CardContainer";
import styled from "@emotion/styled";
import jeffLipBite from "../Assets/jeffLipBite.png";
import jeffPog from "../Assets/jeffPog.png";
import jeffHeart from "../Assets/jeffHeart.png";
import venmoLogo from "../Assets/venmoLogo.webp";
import LoadingIFrame from "./LoadingIFrame";
import SocialHeader from "./SocialHeader";
import MailingCard from "./MailingCard";

const Home = () => {
  const LinkItems = [
    {
      link: "https://discord.gg/JmMAWU6X5E",
      header: "Join My Discord",
      subtext: "Join my Anime Club",
      img: jeffLipBite,
    },
    {
      link: "https://www.twitch.tv/jeffery_zang",
      header: "Follow me on Twitch!",
      subtext: "Join the Zhang Gang :)",
      img: jeffPog,
    },
    {
      link: "https://www.amazon.com/hz/wishlist/ls/WDB34F9BPXCG?ref_=wl_share",
      header: "Amazon Wishlist",
      subtext: "Thank you! 🥰",
      img: jeffHeart,
    },
    {
      link: "https://venmo.com/code?user_id=2054097997922304329&created=1652924740.8868232&printed=1",
      header: "Support me in other ways",
      subtext: "Jeffery_zang",
      img: venmoLogo,
    },
  ];

  const InfoLinkItems = [
    {
      link: "https://reproductiverights.carrd.co/",
      title: "Reproductive Rights Resources",
    },
    {
      link: "https://anti-asianviolenceresources.carrd.co/",
      title: "Stop Asian Hate Resources",
    },
    {
      link: "https://blacklivesmatters.carrd.co/",
      title: "BLM Info",
    },
  ];
  return (
    <MainContent>
      <SocialHeader />
      <CardContainer ratio="2">
        <LoadingIFrame />
      </CardContainer>
      <LinkButtons>
        {LinkItems.map((item, idx) => (
          <ImageClickableCard
            key={`imageLink_${idx}`}
            link={item.link}
            header={item.header}
            subtext={item.subtext}
            img={item.img}
          />
        ))}
      </LinkButtons>
      {InfoLinkItems.map((item, idx) => (
        <ClickableCards
          key={`infoLink_${idx}`}
          link={item.link}
          title={item.title}
        />
      ))}
      <MailingCard />
    </MainContent>
  );
};

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
`;
const LinkButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  *:nth-of-type(even) > div {
    flex-direction: row-reverse;
  }
`;

export default Home;
