import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const CardContainer = ({ link, children, ratio = "none", border = "none" }) => {
  const Comp2Render = link ? ButtonLink : ButtonLinkContainer;
  return (
    <Comp2Render href={link} border={border}>
      <ButtonContainer ratio={ratio}>{children}</ButtonContainer>
    </Comp2Render>
  );
};

export default CardContainer;

export const sizeStyles = css`
  display: flex;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  overflow: hidden;
  padding: 0;
  margin: 10px 0;
`;

export const cardStyles = (props) => css`
  ${sizeStyles}
  border: ${props.border};
  border-radius: 36px;
  background-color: turquoise;
  color: inherit;
  ${!props.noHover &&
  css`
    &:hover {
      transform: scale(1.02);
    }
  `}
`;

const ButtonLinkContainer = styled.div`
  ${cardStyles}
`;

const ButtonLink = styled.a`
  ${cardStyles}
  text-decoration: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  background: none;
  text-align: center;
  word-break: break-word;
  border: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0;
  width: 100%;
  cursor: pointer;
  aspect-ratio: ${({ ratio }) => ratio};
`;
